export const acionamentoConstants = {
  ACIONAMENTO_REQUEST: 'ACIONAMENTO_REQUEST',
  ACIONAMENTO_SUCCESS: 'ACIONAMENTO_SUCCESS',
  ACIONAMENTO_FAILURE: 'ACIONAMENTO_FAILURE',
};

export const editAcionamentoConstants = {
  ACIONAMENTO_EDIT_REQUEST: 'ACIONAMENTO_EDIT_REQUEST',
  ACIONAMENTO_EDIT_SUCCESS: 'ACIONAMENTO_EDIT_SUCCESS',
  ACIONAMENTO_EDIT_FAILURE: 'ACIONAMENTO_EDIT_FAILURE',
};
