import React from 'react';
import MenuPainel from '../components/MenuPainel'
import CssBaseline from '@material-ui/core/CssBaseline';
import AdmAcionamentoGru   from '../components/AcionamentoGru'
import PaperForm from "../components/PaperForm"

class AcionamentoGru extends React.Component {
    render() {
        return (
            <React.Fragment>
                <CssBaseline/>
                <MenuPainel>
                    <PaperForm
                        title={''}
                        margin={'15px 0'}
                        elevation={24}
                        componente={<AdmAcionamentoGru />}>
                    </PaperForm>
                </MenuPainel>
            </React.Fragment>
        );
    }
}

const connectedAssinar = (AcionamentoGru);
export { connectedAssinar as AcionamentoGru};
