import { alertConstants } from '../constants'

export const alertActions = {
  success,
  error,
  clear,
}

function success(message) {
  return { type: alertConstants.SUCCESS, message, open: true, loading: true }
}

function error(message) {
  return { type: alertConstants.ERROR, message, open: true, loading: true }
}

function clear(props, type, message) {
  return { type: alertConstants.CLEAR, varient: type, message: message, props }
}
