import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';


const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const styles = theme => ({
    success: {
        padding: '5px',
        backgroundColor: green[600],
    },
    error: {
        padding: '5px',
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        padding: '5px',
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        padding: '5px',
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing.unit,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    alertBoxSucess:{
        zIndex: '10001 !important',
        backgroundColor: theme.palette.error.dark,
    },
    alertBoxError:{
        zIndex: '10001 !important',
        backgroundColor: green[600],
    }
});

function MySnackbarContent(props) {
    const { classes, className, message, onClose, variant, ...other } = props;
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            className={classNames(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
                    {message}
        </span>
            }
            action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={onClose}
                >
                    <CloseIcon className={classes.icon} />
                </IconButton>,
            ]}
            {...other}
        />
    );
}

MySnackbarContent.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const MySnackbarContentWrapper = withStyles(styles)(MySnackbarContent);



class Alert extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vertical: 'bottom',
            horizontal: 'right',
            variant: 'info'
        };
    }

    render() {
        const { classes, message, type, open, onClose } = this.props;
        const { vertical, horizontal } = this.state;
        return (
            <React.Fragment>
                <Snackbar
                    className={classes.alertBox}
                    anchorOrigin={{ vertical, horizontal }}
                    open={open}
                    onClose={onClose}
                    // autoHideDuration={3000}
                >
                    <MySnackbarContentWrapper
                        open={open}
                        onClose={onClose} variant={type || 'error'} message={message} />
                </Snackbar>
            </React.Fragment>
        );
    }
}

Alert.propTypes = {
    classes: PropTypes.object.isRequired
};

const connectedRegisterPage = (Alert);
export default  withStyles(styles)(connectedRegisterPage);
