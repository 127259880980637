import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";
import connect from "react-redux/es/connect/connect";
import Grid from "@material-ui/core/Grid";
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import Fab from "@material-ui/core/Fab";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";

const styles = theme => ({
    alertText:{
        margin: '3px 0 0 0',
        fontSize: '11px',
        color: 'red'
    },
    sucess: {
        backgroundColor: green[600],
    },
    boxdialog: {
        margin: '0 auto 0 auto',
        '@media only screen and (max-width: 900px)': {
            padding: '0 0 20px 0',
            margin: '0 auto 0 auto',
            '@media screen and (orientation: landscape)': {
                margin: '0 auto',
            }
        },
    },
    dialog_modal:{
        borderRadius: '50%'
    },
    dialog: {
        alignItems:'center',
        justifyContent:'center',
        padding:'0 20px',
        margin: '0 auto'
    },
    titleDialog:{
        width: '100%',
        textAlign: "center",
        fontWeight:"800",
        fontFamily:"Poppins",
    },
    buttonClose:{
        float: "right",
        position: "absolute"
    },
    close:{
        position: "absolute",
        width: '45px',
        top: '2px'
    },
    rootStyle: {

        borderRadius: 20,
    },
    dialogAlert:{
        width: '100%'
    },
    textarea:{
        width: '100%'
    }
});

class AlertLink extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vertical: 'top',
            horizontal: 'center',
            currentTime: 10,
            copySuccess: '',
            maxWidth: 'xs',

        };
        this.handleExited = this.handleExited.bind(this);
    }

    handleExited(){

    };

    copyToClipboard = (e) => {
        this.textArea.select();
        document.execCommand('copy');
        e.target.focus();
        this.setState({ copySuccess: 'Copied!' });
    };

    render() {
        const { classes, open, close, message, url } = this.props;
        return (
            <React.Fragment>
                <Dialog
                    classes={{paper: classes.rootStyle}}
                    open={open}
                    maxWidth={this.state.maxWidth}
                    onExited={()=> this.handleExited()}
                    disableAutoFocus={true}
                    title={message || ""}
                    fullWidth= {true}
                >
                    <DialogActions>
                        <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={close}>
                            <CloseIcon className={classes.icon} />
                        </IconButton>
                    </DialogActions>
                    <DialogTitle className={classes.titleDialog}>
                        <span className={classes.titleDialog}>{message}</span>
                    </DialogTitle>
                    <DialogContent>
                          <textarea readOnly
                              className={classes.textarea}
                              ref={(textarea) => this.textArea = textarea}
                              value={url}
                          />
                    </DialogContent>
                    <DialogActions justify={"center"} >
                        <Grid container justify={'center'} >
                            {
                                document.queryCommandSupported('copy') &&
                                <Fab variant="extended" aria-label="Delete" onClick={this.copyToClipboard} className={classes.fab} color="primary" style={{
                                    fontSize: '10px',
                                    width: '40%',
                                    height: '36px',
                                    background: 'white',
                                    color: 'blue',
                                    boxShadow: 'none',
                                    border: "1px solid",
                                    marginLeft: '5px'
                                }}
                                >
                                    Copiar Link
                                </Fab>
                            }
                        </Grid>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const {message, type, open, url} = state.alertContingencia;
    return {
        message,
        type,
        open,
        url
    };
}

AlertLink.propTypes = {
    classes: PropTypes.object.isRequired
};

const connectedRegisterPage = connect(mapStateToProps)(AlertLink);
export default  withStyles(styles)(connectedRegisterPage);
