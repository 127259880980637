export const userConstants = {
  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  REGISTER_PAYMENT_REQUEST: 'USERS_REGISTER_PAYMENT_REQUEST',
  REGISTER_PAYMENT_SUCCESS: 'USERS_REGISTER_PAYMENT_SUCCESS',
  REGISTER_PAYMENT_FAILURE: 'USERS_REGISTER_PAYMENT_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  RECOVER_PASSWORD_REQUEST: 'RECOVER_PASSWORD_REQUEST',
  RECOVER_PASSWORD_SUCCESS: 'RECOVER_PASSWORD_SUCCESS',
  RECOVER_PASSWORD_FAILURE: 'RECOVER_PASSWORD_FAILURE',

  TRUCLINIC_REQUEST: 'USERS_TRUCLINIC_REQUEST',
  TRUCLINIC_SUCESS: 'USERS_TRUCLINIC_SUCCESS',
  TRUCLINIC_FAILURE: 'USERS_TRUCLINIC_FAILURE',
  
  LOGOUT: 'USERS_LOGOUT',

  GETALL_REQUEST: 'USERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'USERS_GETALL_FAILURE',

  DELETE_REQUEST: 'USERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'USERS_DELETE_FAILURE',

  PLAN_SUCCESS:'USERS_PLAN_SUCCESS',
  PLAN_FAILURE:'USERS_PLAN_FAILURE',
  PLAN_REQUEST:'USERS_PLAN_REQUEST',
};