import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Fab from '@material-ui/core/Fab'
import './App.css'
import logoConecta from '../img/logo_branco.png'
import { history } from '../helpers'
import { connect } from 'react-redux'
import './App.css'
import banner_cad from '../img/Banner.png'
import { alertActions, userActions } from '../actions'

const styles = (theme) => ({
  header: {
    background: `url(${banner_cad})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '100%',
    height: '99vh',
    '@media only screen and (max-width: 900px)': {
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      width: '100%',
    },
  },
  main: {
    // position: 'fixed',
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 300,
    width: '100%',
    height: '',
    fontFamily: 'Poppins',
    paddingTop: '15px',
  },
  button: {
    background: 'none',
    border: '0',
    '@media only screen and (max-width: 900px)': {
      display: 'none',
    },
  },
  image: {
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('xs')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
  },
  text_banner: {
    fontSize: '60px',
    float: 'right',
    padding: '70px 30px 30px 30px',
    color: 'white',
    marginRight: '20%',
    fontWeight: '200',
    lineHeight: '1.2',
    textShadow: '#194082 0.01em 0.01em 0.2em',
    '@media only screen and (max-width: 900px)': {
      fontSize: '40px',
      float: 'right',
      padding: '60px 5% 30px 0px',
      color: 'white',
      marginRight: '0',
      fontWeight: '200',
      lineHeight: '1.2',
      textShadow: '#194082 0.01em 0.01em 0.2em',
    },
  },
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },

  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.6,
    transition: theme.transitions.create('opacity'),
  },

  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing.unit * 1}px ${theme.spacing.unit * 1}px ${theme.spacing.unit + 1}px`,
    fontSize: '10px',
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
  card: {
    maxWidth: 345,
  },
  media: {
    // ⚠️ object-fit is not supported by IE 11.
    objectFit: 'cover',
  },
  menubar: {
    width: '100%',
  },
  showNone: {
    '@media only screen and (max-width: 900px)': {
      display: 'none',
    },
  },
  menuMobile: {
    display: 'none',
    float: 'right',
    margin: '0 10px',
    '@media only screen and (max-width: 900px)': {
      display: 'block',
      padding: '0 0 20px 0',
    },
  },
  padding_top: {
    '@media only screen and (max-width: 900px)': {
      width: '300px',
      padding: '40px 0 20px 0',
    },
  },
})

class Menu extends React.Component {
  constructor() {
    super()
    this.state = {
      menuOpen: false,
    }
    this.logout = this.logout.bind(this)
    this.handleChangePassword = this.handleChangePassword.bind(this)
  }
  state = {
    open: false,
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  handleHome() {
    history.push('/adm')
  }

  logout() {
    this.props.dispatch(userActions.logout())
    this.props.dispatch(alertActions.clear())
  }

  handleChangePassword() {
    this.handleHome()
  }

  handleHistorico() {
    history.push('/historico')
  }

  handleAtividades() {
    history.push('/adm/atividades')
  }
/*
  handleCancelamentosAtivos() {
    history.push('/canc/ativos')
  }
*/
  handleCancelamentosFinalizados() {
    history.push('/canc/finalizados')
  }

  handleTeletriagem() {
    history.push('/teletriagem')
  }

  handleAcionamentos() {
    history.push('/adm')
  }

  handleProfileRoutes(location) {
    let MenuButtons = []

    switch (location) {
      case '/historico':
        MenuButtons = [
          {
            url: '#',
            title: 'Acionamentos',
            width: '10%',
            click: this.handleAcionamentos,
            height: '10px',
          },
          /*{
            url: '#',
            title: 'Cancelamentos Ativos',
            width: '10%',
            click: this.handleCancelamentosAtivos,
            height: '10px',
          },*/

          {
            url: '#',
            title: 'Log Atividades',
            width: '10%',
            click: this.handleAtividades,
            height: '10px',
          },

          {
            id: 1,
            url: '#',
            title: 'Trocar Senha',
            width: '10%',
            click: this.handleChangePassword,
            height: '10px',
          },
          {
            url: '#',
            title: 'Sair',
            width: '10%',
            click: this.logout,
            height: '10px',
          },
        ]
        break
      case '/adm':
        MenuButtons = [
          {
            url: '#',
            title: 'Historico',
            width: '10%',
            click: this.handleHistorico,
            height: '10px',
          },
          /*{
            url: '#',
            title: 'Cancelamentos Ativos',
            width: '10%',
            click: this.handleCancelamentosAtivos,
            height: '10px',
          },*/

          {
            url: '#',
            title: 'Log Atividades',
            width: '10%',
            click: this.handleAtividades,
            height: '10px',
          },
          {
            id: 1,
            url: '#',
            title: 'Trocar Senha',
            width: '10%',
            click: this.handleChangePassword,
            height: '10px',
          },
          {
            url: '#',
            title: 'Sair',
            width: '10%',
            click: this.logout,
            height: '10px',
          },
        ]
        break

      case '/adm/acionamento':
        MenuButtons = [
          {
            url: '#',
            title: 'Historico',
            width: '10%',
            click: this.handleHistorico,
            height: '10px',
          },
          /*{
            url: '#',
            title: 'Cancelamentos Ativos',
            width: '10%',
            click: this.handleCancelamentosAtivos,
            height: '10px',
          },*/
          {
            url: '#',
            title: 'Log Atividades',
            width: '10%',
            click: this.handleAtividades,
            height: '10px',
          },
          {
            id: 1,
            url: '#',
            title: 'Trocar Senha',
            width: '10%',
            click: this.handleChangePassword,
            height: '10px',
          },
          {
            url: '#',
            title: 'Sair',
            width: '10%',
            click: this.logout,
            height: '10px',
          },
        ]
        break
/** 
      case '/cancelamentos':
        MenuButtons = [
          {
            url: '#',
            title: 'Historico',
            width: '10%',
            click: this.handleHistorico,
            height: '10px',
          },
          {
            url: '#',
            title: 'Acionamentos',
            width: '10%',
            click: this.handleAcionamentos,
            height: '10px',
          },
          {
            url: '#',
            title: 'Cancelamentos Ativos',
            width: '10%',
            click: this.handleCancelamentosAtivos,
            height: '10px',
          },
          {
            url: '#',
            title: 'Log Atividades',
            width: '10%',
            click: this.handleAtividades,
            height: '10px',
          },
          {
            id: 1,
            url: '#',
            title: 'Trocar Senha',
            width: '10%',
            click: this.handleChangePassword,
            height: '10px',
          },
          {
            url: '#',
            title: 'Sair',
            width: '10%',
            click: this.logout,
            height: '10px',
          },
        ]
        break
        */
/*
      case '/canc/ativos':
        MenuButtons = [
          {
            url: '#',
            title: 'Historico',
            width: '10%',
            click: this.handleHistorico,
            height: '10px',
          },
          {
            url: '#',
            title: 'Acionamentos',
            width: '10%',
            click: this.handleAcionamentos,
            height: '10px',
          },
          {
            url: '#',
            title: 'Log Atividades',
            width: '10%',
            click: this.handleAtividades,
            height: '10px',
          },
          {
            id: 1,
            url: '#',
            title: 'Trocar Senha',
            width: '10%',
            click: this.handleChangePassword,
            height: '10px',
          },
          {
            url: '#',
            title: 'Sair',
            width: '10%',
            click: this.logout,
            height: '10px',
          },
        ]
        break
*/
/*
      case '/canc/finalizados':
        MenuButtons = [
          {
            url: '#',
            title: 'Historico',
            width: '10%',
            click: this.handleHistorico,
            height: '10px',
          },
          {
            url: '#',
            title: 'Cancelamentos Ativos',
            width: '10%',
            click: this.handleCancelamentosAtivos,
            height: '10px',
          },
          {
            url: '#',
            title: 'Log Atividades',
            width: '10%',
            click: this.handleAtividades,
            height: '10px',
          },
          {
            id: 1,
            url: '#',
            title: 'Trocar Senha',
            width: '10%',
            click: this.handleChangePassword,
            height: '10px',
          },
          {
            url: '#',
            title: 'Sair',
            width: '10%',
            click: this.logout,
            height: '10px',
          },
        ]
        break
*/
      case '/adm/atividades':
        MenuButtons = [
          {
            url: '#',
            title: 'Historico',
            width: '10%',
            click: this.handleHistorico,
            height: '10px',
          },

          {
            url: '#',
            title: 'Acionamentos',
            width: '10%',
            click: this.handleAcionamentos,
            height: '10px',
          },
          {
            id: 1,
            url: '#',
            title: 'Trocar Senha',
            width: '10%',
            click: this.handleChangePassword,
            height: '10px',
          },
          {
            url: '#',
            title: 'Sair',
            width: '10%',
            click: this.logout,
            height: '10px',
          },
        ]
        break
      default:
        MenuButtons = [
          {
            url: '#',
            title: 'Historico',
            width: '10%',
            click: this.handleHistorico,
            height: '10px',
          },
          {
            url: '#',
            title: 'Log Atividades',
            width: '10%',
            click: this.handleAtividades,
            height: '10px',
          },
          {
            url: '#',
            title: 'Acionamentos',
            width: '10%',
            click: this.handleAcionamentos,
            height: '10px',
          },
          {
            id: 1,
            url: '#',
            title: 'Trocar Senha',
            width: '10%',
            click: this.handleChangePassword,
            height: '10px',
          },
          {
            url: '#',
            title: 'Sair',
            width: '10%',
            click: this.logout,
            height: '10px',
          },
        ]
        break
    }
    return MenuButtons
  }

  handleDefaultProfileRoutes() {
    return [
      {
        id: 1,
        url: '#',
        title: 'Trocar Senha',
        width: '10%',
        click: this.handleChangePassword,
        height: '10px',
      },
      {
        url: '#',
        title: 'Sair',
        width: '10%',
        click: this.logout,
        height: '10px',
      },
    ]
  }

  handleDefaultProfileAssistRoutes() {
    return [
      {
        url: '#',
        title: 'Teletriagem',
        width: '10%',
        click: this.handleTeletriagem,
        height: '10px',
      },
      {
        url: '#',
        title: 'Trocar Senha',
        width: '10%',
        click: this.handleChangePassword,
        height: '10px',
      },
      {
        url: '#',
        title: 'Sair',
        width: '10%',
        click: this.logout,
        height: '10px',
      },
    ]
  }

  render() {
    const { classes, stepper } = this.props
    let location = window.location.pathname
    let MenuButtons = []


    return (
      <div className={classes.header}>
        <div id='menu' className={classes.main}>
          <Grid item xs={1}></Grid>
          <Grid item xs={2}>
            <Fab
              disableRipple={true}
              variant='extended'
              aria-label='Delete'
              className={classes.fab}
              style={{
                background: 'transparent',
                marginTop: '-15px',
                padding: '0px',
                border: '0',
                boxShadow: 'none',
                outline: '0',
              }}
              onClick={this.handleHome}
            >
              <img style={{ margin: '0 0 0 0', zIndex: '1' }} width='120px' alt='Logo Conecta' src={logoConecta} />
            </Fab>
          </Grid>
          <Grid className={classes.padding_top} style={{ textAlign: 'right' }} item xs={6}>
            {stepper}
          </Grid>
          <Grid style={{ textAlign: 'right' }} item xs={2}>
          </Grid>
          <Grid item xs={1}></Grid>
          {this.props.children}
        </div>
      </div>
    )
  }
}

Menu.propTypes = {
  classes: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
  const { message, type, loading } = state.alert
  return {
    message,
    type,
    loading,
  }
}

const connnectedMenuPainel = connect(mapStateToProps)(Menu)
export default withStyles(styles)(connnectedMenuPainel)
