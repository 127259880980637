import { userService } from './user.service';

require('dotenv').config();

const URL_CONECTA = process.env.REACT_APP_API_CONECTA;

export const conveniosService = {
    GetAllConvenios,
};

function GetAllConvenios() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    };
    return (userService.fetchWithTimeout(`${URL_CONECTA}/servico/bi/convenios`,requestOptions, 5000)
        .then(userService.handleResponse))
}

