import moment from 'moment';

export const validations = {
    validationEmail,
    validationPassword,
    validationName,
    validationGenero,
    validationLastName,
    validationCPF,
    validationCPassword,
    validationTerms,
    validationMessage,
    validationSubject,
    validationTelefone,
    validationbirthDate,
    validationRg,
    validationIdRequisicaoParceiro,
    validateRegion,
    validatePais,
    validateCidade,
    formataCPF,
    validarCPF,
    validationPassage,
    validateCity,
    validNumberCard,
    validationCsvDate,
    validationCsvRangeDate,
    validConvenio,
    validationActivitiesCsvRangeDate
};


function validConvenio(convenio){
    return validConvenio = convenio !== "";
}

function validNumberCard(numberCard,convenio){
    if (numberCard){
        return (numberCard.length >= 12 && numberCard.length <= 20) 
    } else {
        return false
    }
}

function validateCity(city, codPais){
    let valid = (codPais != 'br') || (city && city.length >=3 && codPais == 'br') 
    return valid
}

function validationPassage(passagem){
    var validPassage = passagem.length >= 3;
    return validPassage;
}

function validationEmail(email) {
    var validEmail = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    return !validEmail;
}

function validationPassword(password) {
    var validPassword = password.length >= 6;
    return !validPassword;
}

function validationCPassword(password, cpassword) {
    return String(password) === String(cpassword) ? false : true;
}

function validationName(name) {
    var validName = name.length >= 2;
    return !validName;
}

function validateRegion(region,pais) {
    var validRegion = region && region.length <= 3;
    if(pais == 'br' || pais == 'us'){
        validRegion = region.length >= 2
    }else{
        validRegion = true;
    }
    return validRegion;
}

function validatePais(pais) {
    var validPais = pais && pais.length <= 3;
    return !validPais;
}

function validateCidade(cidade,pais) {
    var validCity = false;
    switch((pais == 'br')){
        case true:
            validCity = cidade.length <= 3;
        break;
        case false:            
            validCity = false;
        break;
    }
    return !validCity;
}

function validationGenero(age) {
    return !(age === 'M' || age === 'F')
}

function validationTerms(terms) {
    return !terms;
}

function validationLastName(lastname) {
    var validLastName = lastname.length >= 3;
    return !validLastName;
}

function validationCPF(CPF) {
    var validCPF = TestaCPF(CPF);
    return !validCPF;
}

function validationbirthDate(birthDate) {
    var data_1 = moment(birthDate, "DD/MM/YYYY").format("YYYY-MM-DDTHH:mm:ssZ");
    var data = moment(data_1).isAfter(moment(moment(new Date()).format("YYYY-MM-DDTHH:mm:ssZ")));
    var reg = /(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d/;
    return !(birthDate.match(reg) && !data)
}

function validationCsvDate(csvDate) {
    var reg = /(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d/;
    return (csvDate.match(reg))
}

function validationCsvRangeDate(csvDateIni,csvDateFim) {
    var data_1 = moment(csvDateIni, "DD/MM/YYYY").format("YYYY-MM-DDTHH:mm:ssZ");
    var diff = moment(moment(csvDateFim, "DD/MM/YYYY").format("YYYY-MM-DDTHH:mm:ssZ")).diff(data_1,'months');
    return (diff>3)
}

function validationActivitiesCsvRangeDate(csvDateIni,csvDateFim) {
    var data_1 = moment(csvDateIni, "DD/MM/YYYY").format("YYYY-MM-DDTHH:mm:ssZ");
    var diff = moment(moment(csvDateFim, "DD/MM/YYYY").format("YYYY-MM-DDTHH:mm:ssZ")).diff(data_1,'months');
    return (diff>3)
}

function validationMessage(message) {
    var validMessage = message.length >= 3;
    return !validMessage;
}

function validationSubject(subject) {
    var validSubject = subject.length >= 3;
    return !validSubject;
}

function validationTelefone(telefone) {
    var validTelefone = telefone ? telefone.toString().length >= 5:false;
    return validTelefone;
}

function validationRg(rg) {
    var validRg = rg.length >= 3;
    return !validRg;
}

function validarCPF(cpf){
    let total = 0;
    let rest;

    if(cpf && cpf.length > 0){
        cpf = cpf.toString().replace(/\./g,"").replace(/-/g,"");
        if(cpf == '00000000000') return false;
        for(let i=1; i<=9; i++) total = total + parseInt(cpf.substring(i-1, i)) * (11 - i);
        rest = (total * 10) % 11;

        if((rest == 10) || (rest == 11)) rest = 0;
        if(rest != parseInt(cpf.substring(9, 10))) return false;

        total = 0;
        for(let i = 1; i <= 10; i++) total = total + parseInt(cpf.substring(i-1, i))*(12-i);
        rest = (total * 10) % 11;

        if((rest == 10) || (rest == 11)) rest = 0;
        if(rest != parseInt(cpf.substring(10, 11))) return false;
        return true;
    }    
    return true;
}

function formataCPF(cpf){
    cpf = cpf.toString().replace(/[^\d]/g, "");
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

function TestaCPF(strCPF) {
    strCPF = strCPF.replace(/\./g,'').replace('-','');
    var Soma;
    var Resto;
    Soma = 0;
    if (strCPF === "00000000000") return false;

    for (let i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11))  Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10)) ) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto == 10) || (Resto == 11))  Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11) ) ) return false;
    return true;
}

function validationIdRequisicaoParceiro(id) {
    var validID = id.length >= 3;
    return validID ? false : true;
}
