import {userConstants} from '../constants';
import { userService } from '../services';
import { alertActions, alertContingenciaActions } from './';
import { history } from '../helpers';

export const userActions = {
    login,
    logout,
    register,
    put,
    getAll,
    RecoverPassword
};

function login(username, password) {
    return dispatch => {
        dispatch(request({ username }));

        userService.login(username, password)
            .then(
                user => {
                    dispatch(success(user));
                    if (user.user.id_perfil.descricao === "Gestor" || user.user.id_perfil.descricao === "Administrativo" || user.user.id_perfil.descricao === "Suporte"){
                        history.push('/adm')
                    } else if (user.user.id_perfil.descricao === "Empresa - Administrativo" && user.user.convenio !== ""){
                        history.push('/painel')
                    } else if (user.user.id_perfil.descricao === "Assistencial"){
                        history.push('/teletriagem')
                    }
                    else{
                        dispatch(failure('fail'));
                        dispatch(alertActions.error('Esse perfil não tem permissão.'));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.error_description));
                }
            ).catch( error => {
                dispatch(failure(error.toString()))
            });
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) {console.log(error); return { type: userConstants.LOGIN_FAILURE, error } }
}

function RecoverPassword(username) {
    return dispatch => {
        dispatch(request({ username }));
        userService.RecoverPassword(username)
            .then(
                user => {
                    dispatch(alertActions.success(user.message));
                    history.push('/');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.error_description));
                }
            ).catch( error => {
            dispatch(failure(error.toString()))
        });
    };

    function request(user) { return { type: userConstants.RECOVER_PASSWORD_REQUEST, user }}
    function success(user) { return { type: userConstants.RECOVER_PASSWORD_SUCCESS, user }}
    function failure(error) { return { type: userConstants.RECOVER_PASSWORD_FAILURE, error}}
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

function register(user, onNext) {
    var tempData = user.birthDate.split("/");
    user.date = `${tempData[2]}-${tempData[1]}-${tempData[0]}`;
    return dispatch => {
        dispatch(request(user));
        userService.register(user)
            .then(
                dados => {
                    var link = document.createElement("a");
                    link.setAttribute("href", dados);
                    link.setAttribute("target", '_blank');
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.message));
                }
            ).catch( error => {
            dispatch(failure(error.toString()))
        });
    };

    function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

function put(user) {
    var tempData = user.birthDate.split("/");
    user.date = `${tempData[2]}-${tempData[1]}-${tempData[0]}`;
    return dispatch => {
        dispatch(request(user));
        userService.put(user)
            .then(
                dados => {
                    dispatch(success());
                    dispatch(alertActions.success('Acionamento atualizado.'));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.message));
                }
            ).catch( error => {
            dispatch(failure(error.toString()))
        });
    };

    function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

function getAll() {
    return dispatch => {
        dispatch(request());
        userService.getAll()
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

