import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {Paper, DialogTitle} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

const   styles = theme => ({
    boxdialog: {
        width:'120%',
        margin: '0 auto 0 auto',
        '@media only screen and (max-width: 900px)': {
            padding: '0 0 20px 0',
            margin: '0 auto 0 auto',
            '@media screen and (orientation: landscape)': {
                margin: '0 auto',
            }
        },
    },
    dialog_modal:{
        borderRadius: '50%'
    },
    dialog: {
        alignItems:'center',
        justifyContent:'center',
        padding: 10,
        margin: '0 auto'
    },
    titleDialog:{
        textAlign: "center",
        fontWeight:"800"
    },
    subtitleDialog:{
        width: '100%',
        fontSize: "12px",
        color: "#707070",
        textAlign: "center",
    },
    buttonClose:{
        float: "right",
        position: "absolute"
    },
    icon: {
        fontSize: 20,
    },
    close:{
        position: "absolute",
        width: '45px',
        top: '2px'
    },
    root: {
        width: '650px',
        borderRadius: 20,
        '@media only screen and (max-width: 900px)': {
            width: '100%',
            height: '100%'
        }
    },

});

class ModalForm extends React.Component {
    render() {
        const { classes, title, subtitle,open, close, componente, elevation, widthGrid, width, height, margin } = this.props;
        return (
            <React.Fragment>
                <Grid container alignItems={"center"} justify="center">
                    <Grid item style={{width: widthGrid}}>
                    <Paper elevation={elevation?elevation:2}
                     style={{width: width, height:height, margin: margin}}
                      className={classes.root} open={open} onClose={close}>
                        {title &&
                        <DialogTitle className={classes.titleDialog}>
                            <div>
                                <span className={classes.titleDialog}>{title}</span>
                            </div>
                            {subtitle &&
                                <div>
                                    <span className={classes.subtitleDialog}>{subtitle}</span>
                                </div>
                            }
                        </DialogTitle>
                        }
                        <Grid className={classes.dialog}>
                                {componente}
                        </Grid>

                    </Paper>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

ModalForm.propTypes = {
    classes: PropTypes.object.isRequired
};

const connectedRegisterPage = ModalForm;
export default  withStyles(styles)(connectedRegisterPage);
