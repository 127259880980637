import 'react-app-polyfill/ie9'
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { Router, Route } from 'react-router-dom'
import { history } from './helpers'
// import { Notifications } from './components/AlertBrowser';
import { store } from './helpers'
import {  Acionamento, AcionamentoGru } from './Adm'

render(
  <Provider store={store}>
    {/*<Notifications />*/}
    <Router history={history}>
      <div>
      {<Route path='/gruairport' component={AcionamentoGru} />}
        {<Route path='/galeao' component={Acionamento} />}
      </div>
    </Router>
  </Provider>,
  document.getElementById('root')
)
