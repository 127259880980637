import { userService } from './user.service';

require('dotenv').config();

const URL_CONECTA = process.env.REACT_APP_API_CONECTA;
export const acionamentoService = {
  GetAllAcionamentos,
  deleteAcionamentos,
  confirmedAcionamentos,
  getCountryAcionamentos,
  getRegionsByCountry,
  getCityByRegions,
  GetAddressFromCep,
  paginateAllAcionamentos,
  getUrlImages
};

function getCountryAcionamentos() {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/adm/acionamentos/country`,
      requestOptions,
      30000
    )
    .then(userService.handleResponse);
}

function getRegionsByCountry(region) {
  let regiao = region ? (region.length <= 0 ? 'nd' : region) : 'nd';

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/adm/acionamentos/states/${regiao}`,
      requestOptions,
      30000
    )
    .then(userService.handleResponse);
}

function getCityByRegions(city) {
  let cidade = city ? (city.length <= 0 ? 'nda' : city) : 'nda';

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/adm/acionamentos/cities/${cidade}`,
      requestOptions,
      30000
    )
    .then(userService.handleResponse);
}

function GetAddressFromCep(cep) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return userService
    .fetchWithTimeout(
      `https://viacep.com.br/ws/${cep}/json/`,
      requestOptions,
      30000
    )
    .then(userService.handleResponse);
}
function paginateAllAcionamentos(pageSize=5,page=1,queryString=""){
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body:JSON.stringify({
      query:queryString
    })
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/adm/acionamentos/paginate/${pageSize}/${page}`,
      requestOptions,
      50000
    ).then(userService.handleResponse);
}


function getUrlImages(bucketObject={}){
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body:JSON.stringify({
      bucketInfo:bucketObject
    })
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/documents/url`,
      requestOptions,
      50000
    ).then(userService.handleResponse);
}

function GetAllAcionamentos() {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/adm/acionamentos/getall`,
      requestOptions,
      5000
    )
    .then(userService.handleResponse);
}

function deleteAcionamentos(id, reason) {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      reason,
      cancelUser: {},
    }),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/adm/acionamentos/delete/${id}`,
      requestOptions,
      5000
    )
    .then(userService.handleResponse);
}

function confirmedAcionamentos(acionamento) {

  var {
    email,
    name,
    lastName,
    cpf,
    passport,
    date,
    nacionalidade,
    sex,
    phone,
    phone2,
    phone3,
    rg,
    id_requisicao_parceiro,
    convenio,
    numberCard,
    userType,
    createDate,
    incompany,
    city,
    regiao,
    pais,
    codPais,
    flgNewPassage,
    urlCall,
    confirmedTime,
    editionTime,
    startTime,
    idVida,
    externalId,
    idMotivoTriagem,
    motivoTriagem,
    docsReferences,
    signUpDocuments
  } = acionamento;
  convenio = convenio.split('|');
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email: email,
      createDate: createDate,
      convenio: convenio[0],
      convenioDescricao: convenio[1],
      incompany: incompany,
      name: name,
      numberCard: numberCard,
      userType: userType,
      lastName: lastName,
      document: nacionalidade === 'B' ? cpf : passport,
      documentType: nacionalidade === 'B' ? 'CPF' : 'PASSAPORTE',
      rg: rg,
      birthDate: date,
      phone: phone,
      homePhone: phone2,
      phone2: phone3,
      gender: sex,
      partnerID: id_requisicao_parceiro,
      nationality: 'Brasileira',
      naturalness: null,
      city: city,
      state: regiao,
      country: pais,
      codPais: codPais,
      flgNewPassage: true,
      urlCall: urlCall,
      produto: 'PA Virtual',
      confirmedTime: confirmedTime,
      editionTime: editionTime,
      idVida: idVida,
      startTime: startTime,
      externalId: externalId,
      idMotivoTriagem: idMotivoTriagem,
      motivoTriagem: motivoTriagem,
      docsReferences: docsReferences,
      signUpDocuments: signUpDocuments
    }),
  };
  return userService
    .fetchWithTimeout(
      `${URL_CONECTA}/servico/adm/acionamentos/confirmed/${acionamento._id}`,
      requestOptions,
      90000
    )
    .then(userService.handleResponse);
}
