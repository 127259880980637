import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import iconconvenio from '../icons/icon_convenio.svg';
import data from '../icons/icon_data.svg';
import pciente from '../icons/icon_paciente.svg';
import sexo from '../icons/icon_gen.svg';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { connect } from 'react-redux';
import { acionamentoService } from '../services/acionamento.service';

import {
  alertActions,
  userActions,
  alertContingenciaActions,
  acionamentoActions,
} from '../actions';
import { validations } from '../helpers/validations';
import Fab from '@material-ui/core/Fab';
import './App.css';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Alert from '../components/Alert';
import ModalConvenio from '../components/ModalConvenio';
import ModalImage from '../components/ModalImage';
//import ModalConfirmPassage from '../components/ModalConfirmPassage';
import classNames from 'classnames';
import AlertLink from '../components/AlertLink';
import { history } from '../helpers';
import InputMask from 'react-input-mask';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme) => ({
  main: {
    padding: '20px',
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '700px',
    width: '100%',
    fontFamily: 'Poppins',
    paddingTop: '0',
    margin: '0 auto 0 auto',
  },
  margin: {
    margin: theme.spacing(),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    flexBasis: 200,
  },
  input: {
    width: '100%',
    padding: '8px 0 8px 0',
    fontSize: '15px',
  },
  img_inputs: {
    top: '10px !important',
    width: '20px',
    height: '20px',
  },
  img_inputs_sem: {
    width: '0 !important',
    height: '0 !important',
  },
  formulario: {
    margin: 0,
    padding: 0,
    width: '100%',
  },
  tituloPagina: {
    color: 'black',
    fontFamily: 'Arial',
    fontSize: '32px !important',
    fontWeight: 'bold',
  },
  erroValidation: {
    color: 'red',
  },
  alertBox: {
    zIndex: '10001 !important',
  },
  overlay: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: '10000',
    backgroundPosition: 'center center no-repeat',
  },
  alertText: {
    margin: '3px 0 0 0',
    fontSize: '9px',
    color: 'red',
  },

  loaderAddress: {
    position: 'relative',
    left: '280px',
    bottom: '43px',
  },

  alertTextTop: {
    marginTop: -15,
  },
  alertTextBottom: {
    marginTop: -8,
  },
  inputMobile: {
    width: '50%',
    '@media only screen and (max-width: 900px)': {
      width: '100%',
    },
  },
  inputBirth: {
    width: '100%',
    padding: '8px 0 0 0',
    fontSize: '12px',
    '#::placeholder': {
      fontSize: '12px',
    },
  },
  input_phone: {
    fontSize: '12px',
    padding: '10px 0 2px 0',
    '#::placeholder': {
      fontSize: '12px',
    },
    '@media only screen and (max-width: 900px)': {
      width: '100%',
    },
  },
});

class AcionamentoGru extends React.Component {
  constructor(props) {
    console.log(process.env.REACT_APP_API_RIO_GAL);
    console.log(process.env.REACT_APP_API_CONECTA);
    super(props);
    this.state = {
      birthDate: '    -    ',
      numberformat: '1320',
      showCard: false,
      loading: false,
      signedRGURL:"",
      user: {
        passage: false,
        cpfrequire: false,
        ceprequire: false,
        name: '',
        editionTime: '',
        lastName: '',
        cpf: '',
        email: '',
        birthDate: '',
        startTime: '',
        password: '',
        cpassword: '',
        loadingsub: false,
        submitted: false,
        checkTerms: false,
        showNotifications: false,
        numberCard: '',
        userType: '',
        sex: '',
        phone: '',
        phone2: '',
        phone3: '',
        rg: '',
        passport: '',
        convenio: `${process.env.REACT_APP_API_GRU_CONV}|GRU Airport`,
        nacionalidade: '',
        pais: '',
        codPais: '',
        city: '',
        id_requisicao_parceiro: '',
        regiao: '',
        flgExistPassage: false,
        convenioDescricao: '',
        streetCep: '',
        cep: '',
        signUpDocuments:[],
        docsReferences:[]
      },
      country: {
        result: [],
      },
      aliasConvenio: null,
      convenioValid: false,
      numberCardValid: false,
      openModal: false,
      openModalCard: false,
      openModalConfirmacao: false,
      blurPhone: false,
      message: '',
      type: '',
      open: false,
      paisValid: false,
      phoneValid: false,
      regionValid: false,
      cityValid: false,
      vertical: 'top',
      horizontal: 'center',
      valDate: 'text',
      submitted: false,
      registry: false,
      formValid: false,
      cepValid: false,
      loaderForAddress: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmitConfirmed = this.handleSubmitConfirmed.bind(this);
    this.TextMaskCustom = this.TextMaskCustom.bind(this);
    this.TextMaskCustomCPF = this.TextMaskCustomCPF.bind(this);
    this.PhoneMask = this.PhoneMask.bind(this);
    this.PhoneMask2 = this.PhoneMask2.bind(this);
    this.PhoneMask3 = this.PhoneMask3.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCloseAlertLink = this.handleCloseAlertLink.bind(this);
    this.handleCloseModalConvenio = this.handleCloseModalConvenio.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleValidFormulario = this.handleValidFormulario.bind(this);
    this.validationCadastro = this.validationCadastro.bind(this);
    this.handleClickOpenModalConfirmacao = this.handleClickOpenModalConfirmacao.bind(
      this
    );
    this.handleCloseModalConfirmacao = this.handleCloseModalConfirmacao.bind(
      this
    );
    this.handleShowNotification = this.handleShowNotification.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleChangePais = this.handleChangePais.bind(this);
    this.handleChangeRegiao = this.handleChangeRegiao.bind(this);
    this.handleChangeRegiaoKeyDown = this.handleChangeRegiaoKeyDown.bind(this);
    this.handleChangeCidadeKeyDown = this.handleChangeCidadeKeyDown.bind(this);
    this.handleChangePaisKeyDown = this.handleChangePaisKeyDown.bind(this);
    this.handleChangeCity = this.handleChangeCity.bind(this);
    this.handleChangeCard = this.handleChangeCard.bind(this);
    this.handleSameModalGeneratePassage = this.handleSameModalGeneratePassage.bind(
      this
    );
    this.handleNewGeneratePassage = this.handleNewGeneratePassage.bind(this);
    this.handleCloseModalCard = this.handleCloseModalCard.bind(this);
    this.handleChangeConvenio = this.handleChangeConvenio.bind(this);
    this.handleChangeCep = this.handleChangeCep.bind(this);
    this.TextMaskCep = this.TextMaskCep.bind(this);
    this.handleSearchAddress = this.handleSearchAddress.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value,
      },
    });
  }

  handleChangeNacionality(e) {
    const { name, value } = e.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value,
        streetCep: '',
        pais: '',
        codPais: '',
        regiao: '',
        city: '',
      },
    });
  }

  handleChangeCep(event) {
    const { name, value } = event.target;
    const { user } = this.state;

    this.setState({
      user: {
        ...user,
        [name]: value,
      },
    });

    if (this.state.user.streetCep.length < 8) {
      this.setState({ loading: false });
    }
  }

  handleChangeConvenio(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    let idConvenio = value.split('|')[0];
    let showNumber = false;
    let validateNumberCard = true;
    if (
      idConvenio === '5' ||
      idConvenio === '30' ||
      idConvenio === '51' ||
      idConvenio === '79'
    ) {
      showNumber = true;
      validateNumberCard = false;
    }
    this.setState({
      user: {
        ...user,
        [name]: value,
      },
      showCard: showNumber,
      numberCardValid: validateNumberCard,
    });
  }

  handleChangeCard(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    let validateNumberCard = true;
    let idConvenio = user.convenio.split('|')[0];
    if (
      idConvenio === '5' ||
      idConvenio === '30' ||
      idConvenio === '51' ||
      idConvenio === '79'
    ) {
      validateNumberCard = validations.validNumberCard(value);
    }
    this.setState({
      user: {
        ...user,
        [name]: value,
      },
      numberCardValid: validateNumberCard,
    });
  }

  handleCloseModalCard() {
    this.setState({ openModalCard: false });
  }
  handleChangePais(event, values) {
    let val = {};
    switch (values) {
      case null:
        val = {
          name: '',
          code: '',
        };
        break;
      default:
        val = {
          ...values,
          name: !values.hasOwnProperty('name') ? '' : values.name,
          code: !values.hasOwnProperty('code') ? '' : values.code,
        };
        break;
    }
    const { name, code } = val;
    const { user } = this.state;

    let json = {
      'Brasil':'br',
      'Brazil':'br',
      'United States of America':'us',
      'Estados Unidos':'us',
    }

    this.setState({
      user: {
        ...user,
        streetCep:'',
        pais: name,
        codPais: json[name],
        regiao: '',
      },
    });

    

  }

  handleChangeRegiaoKeyDown(event) {
    const { name, value } = event.target;
    let val = {};
    switch (value) {
      case null:
        val = {
          region: '',
        };
        break;
      default:
        val = {
          region: value,
        };
        break;
    }
    const { region } = val;
    const { user } = this.state;

    this.setState({
      user: {
        ...user,
        regiao: region,
        city: '',
      },
    });
  }

  handleChangePaisKeyDown(event) {
    const { value } = event.target;
    let val = {};
    switch (value) {
      case null:
        val = {
          name: '',
        };
        break;
      default:
        val = {
          name: value,
        };
        break;
    }

    const { name, code } = val;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        pais: name,
        regiao: '',
      },
    });
  }

  handleChangeCity(event, values) {
    let val = {};
    switch (values) {
      case null:
        val = {
          Estado: '',
          Nome: '',
          ID: '',
        };
        break;
      default:
        val = {
          ...values,
          Nome: !values.hasOwnProperty('Nome') ? '' : values.Nome,
        };
        break;
    }

    const { Nome } = val;
    const { user } = this.state;

    this.setState({
      user: {
        ...user,
        city: Nome,
      },
    });
  }

  handleChangeCidadeKeyDown(event) {
    const { name, value } = event.target;
    let val = {};
    switch (value) {
      case null:
        val = {
          Nome: '',
        };
        break;
      default:
        val = {
          Nome: value,
        };
        break;
    }
    const { Nome } = val;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        city: Nome,
      },
    });
  }

  handleChangeRegiao(event, values) {
    let val = {};
    switch (values) {
      case null:
        val = {
          region: ''
        };
        break;
      default:
        val = {
          ...values,
          region: !values.hasOwnProperty('region') ? '' : values.region,
        };
        break;
    }
    
    const { region } = val;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        regiao: region,
        city:''
      },
    });
  }

  async componentDidMount() {
    const { editAcionamento } = this.props;
    let user = editAcionamento;
    let validateNumberCard = true;
    if (user.name) {
      if (
        user.convenio === '5' ||
        user.convenio === '30' ||
        user.convenio === '51' ||
        user.convenio === '79'
      ) {
        this.setState({
          showCard: true,
        });

        validateNumberCard = validations.validNumberCard(user.numberCard);
      }
      let { streetCep = '', signUpDocuments = [], docsReferences = [] } = user;

      this.setState({
        aliasConvenio: true,
        numberCardValid: validateNumberCard,
        user: {
          ...editAcionamento,
          editionTime: user.editionTime,
          convenio: `${user.convenio}|${user.contrato}`,
          convenioDescricao: user.contrato,
          contrato: user.contrato,
          cpfrequire: false,
          ceprequire: false,
          name: user.name,
          lastName: user.lastName,
          cpf: user.document,
          email: user.email,
          birthDate: moment(user.birthDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
          password: '',
          cpassword: '',
          loadingsub: false,
          submitted: false,
          checkTerms: false,
          showNotifications: false,
          sex: user.gender,
          phone: user.phone,
          phone2: user.homePhone,
          phone3: user.phone2,
          rg: user.rg,
          passport: user.document,
          nacionalidade: user.documentType
            ? user.documentType === 'CPF'
              ? 'B'
              : 'E'
            : '',
          numberCard: user.numberCard,
          startTime: user.startTime,
          userType: user.userType,
          city: user.city,
          regiao: user.state,
          codPais:user.codPais,
          pais: user.country,
          id_requisicao_parceiro: user.partnerID,
          streetCep: streetCep,
          signUpDocuments:signUpDocuments,
          docsReferences:docsReferences
        },
      });
    }
  }

  handleValidFormulario(event, edit) {
    event.preventDefault();
    this.setState({ submitted: true });
    this.validationCadastro('pais');
    this.validationCadastro('regiao');
    this.validationCadastro('city');
    const { user, aliasConvenio } = this.state;

    var birthDate = user.birthDate;
    if (birthDate) {
      var arrayDate = birthDate.split('/');
      var day = Number(arrayDate[0]),
        month = Number(arrayDate[1]),
        year = Number(arrayDate[2]);

      var formBirth = moment([year, month, day]);
      var now = moment();
      var years = now.diff(formBirth, 'years');
      if (years >= 18 && !this.state.user.cpf) {
        this.setState({
          cpfrequire: true,
        });
      } else {
        this.setState({
          cpfrequire: false,
        });
      }
    }
    if (this.state.formValid && edit) {
      this.handleSubmitEdit();
    }
    if (this.state.formValid && !aliasConvenio) {
      this.handleSubmit();
    } else if (this.state.formValid && aliasConvenio) {
      this.handleClickOpenModalConfirmacao();
    }
  }

  handleSubmit = () => {
    const { user } = this.state;
    const { dispatch } = this.props;
    let idConvenio = user.convenio.split('|')[0];
    let tzoffset = new Date().getTimezoneOffset() * 60000;
    let startTime = new Date(Date.now() - tzoffset).toISOString();
    //if(idConvenio === '5' || idConvenio === '30' || idConvenio === '51' || idConvenio === '79'){
    //this.setState({openModalCard:true,openModal:false})
    if (user.convenio !== '') {
      dispatch(userActions.register({ ...user, startTime }));
      this.setState({user:{
        passage: false,
        cpfrequire: false,
        ceprequire: false,
        name: '',
        editionTime: '',
        lastName: '',
        cpf: '',
        email: '',
        birthDate: '',
        startTime: '',
        password: '',
        cpassword: '',
        loadingsub: false,
        submitted: false,
        checkTerms: false,
        showNotifications: false,
        numberCard: '',
        userType: '',
        sex: '',
        phone: '',
        phone2: '',
        phone3: '',
        rg: '',
        passport: '',
        convenio: `${process.env.REACT_APP_API_RIO_GAL}|RIOgaleao`,
        nacionalidade: '',
        pais: '',
        codPais: '',
        city: '',
        id_requisicao_parceiro: '',
        regiao: '',
        flgExistPassage: false,
        convenioDescricao: '',
        streetCep: '',
        cep: '',
        signUpDocuments:[],
        docsReferences:[]
      }})
      this.handleCloseModalConvenio();
    }
  };

  handleSubmitEdit = () => {
    const { user } = this.state;
    const { dispatch } = this.props;
    dispatch(userActions.put(user));
    this.handleCloseModalConvenio();
    this.handleBack();
  };

  async componentWillReceiveProps(received){
    let { user } = this.state;
  }

  handleSearchAddress = async (cep) => {
  };

  getAddress = async (cep) => {
    return await acionamentoService.GetAddressFromCep(cep);
  };

  handleSubmitConfirmed = () => {
    const { user } = this.state;
    const { dispatch } = this.props;
    let tzoffset = new Date().getTimezoneOffset() * 60000;
    let confirmedTime = new Date(Date.now() - tzoffset).toISOString();
    if (user.convenio !== '') {
      dispatch(
        acionamentoActions.confirmedAcionamentos({ ...user, confirmedTime })
      );
      this.handleCloseModalConfirmacao();
    }
  };

  handleShowNotification = () => {
    const { user } = this.state;

    if (this.state.user.showNotifications) {
      this.setState({
        user: {
          ...user,
          showNotifications: false,
        },
      });
    } else {
      this.setState({
        user: {
          ...user,
          showNotifications: true,
        },
      });
    }
  };

  handleChangeCheckbox = () => {
    const { user } = this.state;
    if (user.checkTerms) {
      this.setState({
        user: {
          ...user,
          checkTerms: false,
        },
      });
    } else {
      this.setState({
        user: {
          ...user,
          checkTerms: true,
        },
      });
    }
    this.validationCadastro('terms');
  };

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  handleClickShowCPassword = () => {
    this.setState((state) => ({ showCPassword: !state.showCPassword }));
  };

  handleClose() {
    this.props.dispatch(alertActions.clear());
    this.setState({ open: false, message: '', loadingsub: false });
  }

  handleClear() {
    this.setState({user:{
      passage: false,
      cpfrequire: false,
      ceprequire: false,
      name: '',
      editionTime: '',
      lastName: '',
      cpf: '',
      email: '',
      birthDate: '',
      startTime: '',
      password: '',
      cpassword: '',
      loadingsub: false,
      submitted: false,
      checkTerms: false,
      showNotifications: false,
      numberCard: '',
      userType: '',
      sex: '',
      phone: '',
      phone2: '',
      phone3: '',
      rg: '',
      passport: '',
      convenio: `${process.env.REACT_APP_API_RIO_GAL}|RIOgaleao`,
      nacionalidade: '',
      pais: '',
      codPais: '',
      city: '',
      id_requisicao_parceiro: '',
      regiao: '',
      flgExistPassage: false,
      convenioDescricao: '',
      streetCep: '',
      cep: '',
      signUpDocuments:[],
      docsReferences:[]
    }});
  }

  validationCadastro(event) {
    const {
      name,
      lastName,
      nacionalidade,
      cpf,
      passport,
      email,
      birthDate,
      password,
      cpassword,
      checkTerms,
      sex,
      phone,
      rg,
      id_requisicao_parceiro,
      pais,
      regiao,
      city,
      convenio,
      streetCep,
    } = this.state.user;
    switch (event) {
      case 'name':
        if (name) {
          this.state.nameValid = validations.validationName(name);
        }
        break;
      case 'lastName':
          this.state.lastNameValid = validations.validationLastName(lastName);
        break;
      case 'cpf':
        if (cpf) {
          this.state.cpfValid = validations.validationCPF(cpf);
        }
        break;
      case 'email':
          this.state.emailValid = validations.validationEmail(email);
        break;
      case 'birthDate':
          this.state.birthDateValid = validations.validationbirthDate(
            birthDate
          );
        break;
      case 'phone':
        if (phone) {
          this.state.phoneValid = validations.validationTelefone(phone);
        }
        break;
      case 'terms':
        this.state.termsValid = validations.validationTerms(checkTerms);
        break;
      case 'password':
        if (password) {
          this.state.passwordValid = validations.validationPassword(password);
        }
        break;
      case 'cpassword':
        if (cpassword) {
          this.state.cpasswordValid = validations.validationCPassword(
            password,
            cpassword
          );
        }
        break;
      case 'rg':
        if (rg) {
          this.state.rgValid = validations.validationRg(rg);
        }
        break;
      case 'pais':
        if (pais) {
          this.state.paisValid = validations.validatePais(pais);
        }
        break;
      case 'regiao':
        this.state.regionValid = validations.validateRegion(
          regiao,
          this.state.user.codPais
        );
        break;
      case 'city':
        this.state.cityValid = validations.validateCity(
          city,
          this.state.user.codPais
        );
        break;
      case 'convenio':
        this.state.convenioValid = validations.validConvenio(
          this.state.user.convenio
        );
        break;
      default:
        break;
    }
    const {
      nameValid,
      lastNameValid,
      emailValid,
      passwordValid,
      cpasswordValid,
      termsValid,
      birthDateValid,
      paisValid,
      regionValid,
      cityValid,
      convenioValid,
      numberCardValid,
      phoneValid,
      cepValid,
    } = this.state;

    if (nacionalidade === 'B') {
      if (birthDate) {
        var formBirth = moment(birthDate,'DD/MM/YYYY');
        var now = moment();
        var years = now.diff(formBirth, 'years');
        if (years >= 18 && (!this.state.user.cpf ||  this.state.user.cpf== '')) {
          this.state.formValid =
            phoneValid &&
            convenioValid &&
            cpf &&
            rg &&
            !nameValid &&
            !lastNameValid &&
            !emailValid &&
            !passwordValid &&
            !cpasswordValid &&
            !termsValid &&
            !birthDateValid &&
            (sex === 'M' || sex === 'F');
        } else {
          this.state.formValid =
            phoneValid &&
            convenioValid &&
            !nameValid &&
            !lastNameValid &&
            !emailValid &&
            !passwordValid &&
            !cpasswordValid &&
            !termsValid &&
            !birthDateValid &&
            (sex === 'M' || sex === 'F');
        }
      } else {
        this.state.formValid =
          phoneValid &&
          convenioValid &&
          nacionalidade &&
          !nameValid &&
          !lastNameValid &&
          !emailValid &&
          !passwordValid &&
          !cpasswordValid &&
          !termsValid &&
          !birthDateValid &&
          (sex === 'M' || sex === 'F');
      }
    } else {
      this.state.formValid =
        phoneValid &&
        convenioValid &&
        nacionalidade &&
        !nameValid &&
        !lastNameValid &&
        passport &&
        !emailValid &&
        !passwordValid &&
        !cpasswordValid &&
        !termsValid &&
        !birthDateValid &&
        (sex === 'M' || sex === 'F');
    }
  }

  TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
      <InputMask
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        value={this.state.user.birthDate}
        onChange={this.handleChange}
        style={{ fontSize: '15px', verticalAlign: 'top' }}
        name="birthDate"
        id="formatted-text-mask-input"
        placeholder="DD/MM/YYYY"
        mask="99/99/9999"
        maskChar={null}
        // beforeMaskedValueChange={this.beforeMaskedValueChange}
      />
    );
  }

  TextMaskCep(props) {
    const { inputRef, ...other } = props;
    return (
      <InputMask
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        value={this.state.user.streetCep}
        onChange={this.handleChange}
        style={{ fontSize: '15px', verticalAlign: 'top' }}
        name="streetCep"
        placeholder=""
        mask="99999-999"
        maskChar={null}
      />
    );
  }

  TextMaskCustomCPF(props) {
    const { inputRef, ...other } = props;
    return (
      <InputMask
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        value={this.state.user.cpf}
        onChange={this.handleChange}
        style={{ fontSize: '15px', verticalAlign: 'top' }}
        name="cpf"
        mask="999.999.999-99"
        maskChar={null}
        // beforeMaskedValueChange={this.beforeMaskedValueChange}
      />
    );
  }

  handleBack() {
    history.push('/adm');
  }

  PhoneMask(props) {
    const { inputRef, ...other } = props;
    return (
      <InputMask
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        key={'phone'}
        style={{ fontSize: '15px', verticalAlign: 'middle' }}
        name={'phone'}
        onBlur={this.validationCadastro('phone')}
        value={this.state.user.phone}
        placeholder={''}
        mask={
          this.state.user.nacionalidade === 'B'
            ? '(99) 999999999'
            : '999999999999999'
        }
        maskChar={null}
        onChange={this.handleChange}
        beforeMaskedValueChange={this.beforeMaskedValueChange}
      />
    );
  }
  PhoneMask2(props) {
    const { inputRef, ...other } = props;
    return (
      <InputMask
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        key={'phone2'}
        style={{ fontSize: '15px', verticalAlign: 'middle' }}
        name={'phone2'}
        value={this.state.user.phone2}
        placeholder={''}
        mask={
          this.state.user.nacionalidade === 'B'
            ? '(99) 999999999'
            : '999999999999999'
        }
        maskChar={null}
        onChange={this.handleChange}
        beforeMaskedValueChange={this.beforeMaskedValueChange}
      />
    );
  }
  PhoneMask3(props) {
    const { inputRef, ...other } = props;
    return (
      <InputMask
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        key={'phone3'}
        style={{ fontSize: '15px', verticalAlign: 'middle' }}
        name={'phone3'}
        placeholder={''}
        value={this.state.user.phone3}
        onChange={this.handleChange}
        beforeMaskedValueChange={this.beforeMaskedValueChange}
      />
    );
  }

  beforeMaskedValueChange = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;

    // keep minus if entered by user
    if (
      value.endsWith('-') &&
      userInput !== '-' &&
      !this.state.value.endsWith('-')
    ) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }

    return {
      value,
      selection,
    };
  };

  async handleOpenRG(doc){
    let { signedURL } = await acionamentoActions.generateImageUrl(doc)
    this.setState({signedRGURL:signedURL})
  }

  closeImage(){
    this.setState({signedRGURL:""})
  }

  handleCloseAlertLink() {
    const { dispatch } = this.props;
    dispatch(alertContingenciaActions.clear());
  }

  handleCloseModalConvenio() {
    this.setState({ openModal: false });
  }

  handleClickOpenModalConfirmacao() {
    this.setState({ openModalConfirmacao: true });
  }

  handleCloseModalConfirmacao() {
    this.setState({ openModalConfirmacao: false });
  }

  handleSameModalGeneratePassage() {
    let { user } = this.state;
    const { dispatch } = this.props;
    this.setState({
      user: { ...user, passage: false, flgExistPassage: false },
    });
    dispatch(
      acionamentoActions.confirmedAcionamentos({
        ...{ flgNewPassage: true },
        ...user,
      })
    );
  }

  handleNewGeneratePassage() {
    let { user } = this.state;
    const { dispatch } = this.props;
    this.setState({ user: { ...user, flgExistPassage: true } });
    dispatch(
      acionamentoActions.confirmedAcionamentos({
        ...{ flgNewPassage: false },
        ...user,
      })
    );
  }

  render() {
    const {
      registering,
      open,
      classes,
      message,
      type,
      convenios = [],
      countries = [],
      cities = [],
    } = this.props;
    let { regions } = this.props;
    const {
      user,
      submitted,
      openModal,
      openModalCard,
      openModalConfirmacao,
      aliasConvenio,
      showCard,
      signedRGURL,
      cepValid,
      emailValid
    } = this.state;
    const width = window.innerWidth;
    const inputs_1 = [
      {
        float: 'left',
        imagem: pciente,
        pergunta: 'Nome / Name',
        state: user.name,
        type: 'name',
        blur: this.validationCadastro('name'),
        nValid: this.state.nameValid,
        validacao: '',
      },
      {
        float: 'right',
        imagem: pciente,
        pergunta: 'Sobrenome / Last Name',
        state: user.lastName,
        type: 'lastName',
        blur: this.validationCadastro('lastName'),
        nValid: this.state.lastNameValid,
        validacao: '',
      },
    ];

    const input_cpf = [
      {
        float: 'left',
        imagem: iconconvenio,
        pergunta: 'CPF',
        state: user.cpf,
        type: 'cpf',
        blur: this.validationCadastro('cpf'),
        nValid: this.state.cpfValid,
        require: this.state.cpfrequire,
        validacao: 'CPF inválido.',
        disable: aliasConvenio,
      },
    ];

    const input_rg = [
      {
        float: 'right',
        imagem: iconconvenio,
        pergunta: 'RG',
        state: user.rg,
        type: 'rg',
        blur: this.validationCadastro('rg'),
        nValid: this.state.rgValid,
        validacao: 'Digite o RG.',
      },
    ];

    const inputs_3 = [
      {
        imagem: pciente,
        pergunta: '',
        state: user.email,
        type: 'email',
        blur: this.validationCadastro('email'),
        nValid: this.state.emailValid,
        validacao: 'Email inválido.',
      },
    ];

    const inputs_4 = [
      {
        imagem: iconconvenio,
        pergunta: 'Passaporte',
        state: user.passport,
        type: 'passport',
        blur: this.validationCadastro('passport'),
        nValid: this.state.passportValid,
        validacao: 'Passaporte inválido.',
      },
    ];

    const inputs_5 = [
    ];

    const inputs_6 = [
      {
        imagem: iconconvenio,
        pergunta: 'Numero Carteirinha',
        state: user.numberCard,
        type: 'numberCard',
        nValid: true,
        validacao: 'Identificador parceiro inválido.',
      },
    ];

    const phones = [
      {
        inputRef: this.PhoneMask,
        float: 'left',
        text: 'Campo obrigatório.',
      }
    ];
    
    const convenioSplit = user.convenio.split('|');
    return (
      <div className={classes.main}>
        <ModalImage
                    open={signedRGURL}
                    close={() => this.closeImage()}
                    message="Documento"
                    component={                                
                    <React.Fragment>
                        <img style={{width:"90%",height:"90%",display: 'block',marginLeft: 'auto',marginRight: 'auto'
                      }}src={signedRGURL}></img>
                    </React.Fragment>
          } />

        <Alert
          open={open}
          type={type}
          message={message}
          onClose={() => this.handleClose(type, message)}
        />
        <AlertLink close={() => this.handleCloseAlertLink()} />
        <ModalConvenio
          open={openModal}
          close={() => this.handleCloseModalConvenio()}
          message="Escolha um Convênio"
          onSubmit={() => this.handleSubmit()}
          component={<React.Fragment></React.Fragment>}
        />

        <ModalConvenio
          open={openModalCard}
          close={() => this.handleCloseModalCard()}
          message="Digite o numero da carteirinha"
          onSubmit={() => this.handleSubmitCard()}
          component={<React.Fragment></React.Fragment>}
        />

        <ModalConvenio
          open={openModalConfirmacao}
          close={() => this.handleCloseModalConfirmacao()}
          message="Confirmar acionamento?"
          onSubmit={() => this.handleSubmitConfirmed()}
        />

        <form
          id="Cadastro_Usuario"
          name="Cadastro_Usuario"
          className={classes.formulario}
          onSubmit={this.handleValidFormulario}
        >
          <TextField
            id="standard-select-currency"
            select
            label="Aeroporto / Airport"
            onChange={this.handleChangeConvenio}
            SelectProps={{
              MenuProps: {
                className: classes.menu,
              },
            }}
            margin="normal"
            value={user.convenio}
            input={<Input id="select-multiple-placeholder" />}
            displayEmpty
            onBlur={this.validationCadastro('convenio')}
            name="convenio"
            className={classes.input}
          >
            {aliasConvenio ? (
              <MenuItem
                key={convenioSplit[0]}
                value={`${convenioSplit[0]}|${convenioSplit[1]}`}
              >
                {convenioSplit[1]}
              </MenuItem>
            ) : (
              [{
                "id": process.env.REACT_APP_API_GRU_CONV,
                "cod_convenio": "MDAEMP",
                "cod_escritorio": "AERO_TE",
                "cod_plano": "3811",
                "convenio": "765",
                "escritorio": "6309",
                "plano": "5136",
                "descricao": "GRU Airport",
                "imagem": "",
                "elegibilidade": "1",
                "id_grupo": "1436",
                "desativado": 0,
                "ndep": 99,
                "produto": "5da4d2b958527a17184f95a7",
                "emitir_tiss": 0,
                "padrao_tiss": "",
                "url_tiss": "",
                "url_complemento_tiss": "",
                "max_tiss": 0,
                "id_cliente": 424,
                "example_file_api": "",
                "example_file_manual": "",
                "quantidade_executada": 0,
                "reducao_acrescimo": 0,
                "valor_unitario": "",
                "valor_total": "",
                "valor_total_geral": "",
                "seguradora": 0,
                "base_de_vidas_pe": 1,
                "video_conferencia_status": "truClinic",
                "consultar_acionamentos_api": 0,
                "validar_tipo_elegibilidade": 0,
                "envio_tiss_xml_json": "xml",
                "envio_tiss_carteirinha_cpf": 0,
                "razao_social": "Conc Aerop Inter D Guarulhos S.A.",
                "vigencia_data_de": "2021-01-26",
                "vigencia_data_ate": "2023-01-26",
                "cnpj": ""
              }].map((convenio) => (
                <MenuItem
                  key={convenio.id}
                  value={`${convenio.id}|${convenio.descricao}`}
                >
                  {convenio.descricao}
                </MenuItem>
              ))
            )}
          </TextField>
          {!this.state.convenioValid && (
            <div
              className={classNames(classes.alertText, classes.alertTextTop)}
            >
              Campo obrigatório.
            </div>
          )}

          {(user.numberCard || showCard) && (
            <TextField
              autoFocus
              name="numberCard"
              margin="dense"
              id="card"
              label="Carteirinha"
              type="number"
              value={user.numberCard}
              onChange={this.handleChangeCard}
              fullWidth
            />
          )}
          {showCard && !this.state.numberCardValid && (
            <div className={classNames(classes.alertText)}>
              Por favor, digite um numero de carteirinha válido
            </div>
          )}
          <Grid container spacing={width > 900 ? 8 : 0}>
            {inputs_1.map((questao) => (
              <Grid
                key={questao.type}
                item
                style={{ float: questao.float }}
                className={classNames(
                  classes.inputMobile +
                    (submitted && !questao.state ? ' has-error' : '')
                )}
              >
                <TextField
                  margin="normal"
                  placeholder={questao.validacao}
                  label={questao.pergunta}
                  name={questao.type}
                  value={questao.state}
                  className={classes.input}
                  key={questao.type}
                  onBlur={questao.blur}
                  type={questao.date}
                  onFocus={questao.focus}
                  onChange={this.handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          className={
                            questao.imagem
                              ? classes.img_inputs
                              : classes.img_inputs_sem
                          }
                          src={questao.imagem ? questao.imagem : null}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                {submitted && !questao.state && (
                  <div
                    className={classNames(
                      classes.alertText,
                      classes.alertTextTop
                    )}
                  >
                    Campo obrigatório.
                  </div>
                )}
                {questao.nValid && !(submitted && !questao.state) && (
                  <div
                    className={classNames(
                      classes.alertText,
                      classes.alertTextTop
                    )}
                  >
                    {questao.validacao}
                  </div>
                )}
              </Grid>
            ))}
          </Grid>

          <Select
            value={user.nacionalidade}
            onChange={(e) => this.handleChangeNacionality(e)}
            input={<Input id="select-multiple-placeholder" />}
            displayEmpty
            name="nacionalidade"
            className={classes.input}
            startAdornment={
              <InputAdornment position="start">
                <img className={classes.img_inputs} src={sexo} />
              </InputAdornment>
            }
          >
            <MenuItem value="">
              <em style={{ opacity: '0.5', fontStyle: 'Normal' }}>
                Nacionalidade / Nationality
              </em>
            </MenuItem>
            <MenuItem value={'B'}>Brasileira / Brazilian</MenuItem>
            <MenuItem value={'E'}>Estrangeira / Foreigner</MenuItem>
          </Select>
          {submitted && !user.nacionalidade && (
            <div className={classes.alertText}>Campo obrigatório.</div>
          )}

          <Grid>
            {submitted && this.state.ceprequire && (
              <div
                className={classNames(
                  classes.alertText,
                  classes.alertTextBottom
                )}
              >
                Campo obrigatório.
              </div>
            )}
            {user.nacionalidade === 'B' ? (
              <Grid>
                <TextField
                  label="CPF"
                  margin="normal"
                  placeholder="Digite o CPF."
                  className={classes.inputBirth}
                  disabled={aliasConvenio}
                  onBlur={this.validationCadastro('cpf')}
                  InputProps={{
                    inputComponent: this.TextMaskCustomCPF,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          className={classes.img_inputs}
                          src={iconconvenio}
                        />
                      </InputAdornment>
                    ),
                  }}
                ></TextField>

                {submitted && !user.cpf && this.state.cpfrequire && (
                  <div
                    className={classNames(
                      classes.alertText,
                      classes.alertTextBottom
                    )}
                  >
                    Campo obrigatório.
                  </div>
                )}
                {submitted &&
                  this.state.cpfValid &&
                  !(!user.cpf && this.state.cpfrequire) && (
                    <div
                      className={classNames(
                        classes.alertText,
                        classes.alertTextBottom
                      )}
                    >
                      {'CPF inválido.'}
                    </div>
                  )}

                {input_rg.map((questao, index) => (
                  <Grid key={index}>
                    <TextField
                      margin="normal"
                      placeholder={questao.validacao}
                      label={questao.pergunta}
                      name={questao.type}
                      value={questao.state}
                      className={classes.input}
                      onBlur={questao.blur}
                      type={questao.date}
                      onFocus={questao.focus}
                      onChange={this.handleChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              className={
                                questao.imagem
                                  ? classes.img_inputs
                                  : classes.img_inputs_sem
                              }
                              src={questao.imagem ? questao.imagem : null}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {submitted && !questao.state && this.state.cpfrequire && (
                      <div
                        className={classNames(
                          classes.alertText,
                          classes.alertTextTop
                        )}
                      >
                        Campo obrigatório.
                      </div>
                    )}
                    {questao.nValid && (
                      <div
                        className={classNames(
                          classes.alertText,
                          classes.alertTextTop
                        )}
                      >
                        {questao.validacao}
                      </div>
                    )}
                  </Grid>
                ))}
              </Grid>
            ) : (
              ''
            )}
          </Grid>

          {user.nacionalidade === 'E' ? (
            <Grid container justify="center">
              {inputs_4.map((questao) => (
                <Grid
                  key={questao.type}
                  item
                  style={{ width: '100%' }}
                  className={'form-group'}
                >
                  <TextField
                    label="Passaporte / Passport"
                    margin="normal"
                    name={questao.type}
                    value={questao.state}
                    className={classes.input}
                    placeholder={questao.pergunta}
                    onBlur={questao.blur}
                    type={questao.date}
                    onFocus={questao.focus}
                    onChange={this.handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            className={
                              questao.imagem
                                ? classes.img_inputs
                                : classes.img_inputs_sem
                            }
                            src={questao.imagem ? questao.imagem : null}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {submitted && !questao.state && (
                    <div
                      className={classNames(
                        classes.alertText,
                        classes.alertTextBottom
                      )}
                    >
                      Campo obrigatório.
                    </div>
                  )}
                  {questao.nValid && (
                    <div
                      className={classNames(
                        classes.alertText,
                        classes.alertTextBottom
                      )}
                    >
                      {questao.validacao}
                    </div>
                  )}
                </Grid>
              ))}
            </Grid>
          ) : (
            ''
          )}

          <Grid container spacing={width > 900 ? 8 : 0}>
            <Grid
              item
              style={{ float: 'left' }}
              className={classNames(classes.inputMobile, 'form-group')}
            >
              <TextField
                label="Data de Nascimento / Date of birth"
                margin="normal"
                className={classes.inputBirth}
                onBlur={this.validationCadastro('birthDate')}
                InputProps={{
                  inputComponent: this.TextMaskCustom,
                  startAdornment: (
                    <InputAdornment position="start">
                      <img className={classes.img_inputs} src={data} />
                    </InputAdornment>
                  ),
                }}
              ></TextField>
              {submitted && !user.birthDate && (
                <div
                  className={classNames(
                    classes.alertText,
                    classes.alertTextBottom
                  )}
                >
                  Campo obrigatório.
                </div>
              )}<br/>
              {submitted && this.state.birthDateValid && (
                <div
                  className={classNames(
                    classes.alertText,
                    classes.alertTextBottom
                  )}
                >
                  {'Data de nascimento inválida.'}
                </div>
              )}
            </Grid>

            <Grid
              item
              style={{ float: 'right' }}
              className={classNames(classes.inputMobile, 'form-group')}
            >
              <TextField
                id="standard-select-currency"
                select
                label="Sexo / Gender"
                onChange={this.handleChange}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                margin="normal"
                value={user.sex}
                input={<Input id="select-multiple-placeholder" />}
                displayEmpty
                name="sex"
                className={classes.input}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img className={classes.img_inputs} src={sexo} />
                    </InputAdornment>
                  ),
                }}
              >
                <MenuItem value={'M'}>Masculino / Male</MenuItem>
                <MenuItem value={'F'}>Feminino / Female</MenuItem>
              </TextField>
              {submitted && !user.sex && (
                <div
                  className={classNames(
                    classes.alertText,
                    classes.alertTextTop
                  )}
                >
                  Campo obrigatório.
                </div>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={width > 900 ? 8 : 0}>
            <Grid
              item
              style={{ float: 'left' }}
              className={classNames(classes.inputMobile, 'form-group')}
            >
              {phones.map((phone) => (
              <div >
                <TextField
                  label="Telefone / Phone"
                  margin="normal"
                  className={classes.input}
                  InputProps={{
                    inputComponent: phone.inputRef,
                    startAdornment: (
                      <InputAdornment position="start">
                        <img className={classes.img_inputs} src={pciente} />
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
                {submitted && !user.phone && (
                  <div
                    className={classNames(
                      classes.alertText,
                      classes.alertTextBottom
                    )}
                  >
                    {phone.text}
                  </div>
                )}
              </div>
            ))}
            </Grid>

            <Grid
              item
              style={{ float: 'right' }}
              className={classNames(classes.inputMobile, 'form-group')}
            >
              {inputs_3.map((questao) => (
                <div
                  key={questao.type}
                  style={{ width: '100%' }}
                  className={
                    'form-group' +
                    (submitted && !questao.state ? ' has-error' : '')
                  }
                >
                  <TextField
                    label="E-mail"
                    margin="normal"
                    name={questao.type}
                    value={questao.state}
                    className={classes.input}
                    placeholder={questao.pergunta}
                    onBlur={questao.blur}
                    type={questao.date}
                    onFocus={questao.focus}
                    onChange={this.handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            className={
                              questao.imagem
                                ? classes.img_inputs
                                : classes.img_inputs_sem
                            }
                            src={questao.imagem ? questao.imagem : null}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {submitted && !questao.state && (
                    <div
                      className={classNames(
                        classes.alertText,
                        classes.alertTextTop
                      )}
                    >
                      Campo obrigatório.
                    </div>
                  )}
                  {questao.nValid && !(submitted && !questao.state) &&  emailValid && (
                    <div
                      className={classNames(
                        classes.alertText,
                        classes.alertTextTop
                      )}
                    >
                      {questao.validacao}
                    </div>
                  )}
                </div>
              ))}
            </Grid>
          </Grid>
          
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            
          </Grid>

          

          
          
          <Grid container spacing={width > 900 ? 8 : 0}>
            
            <Grid item xs={12} sm={5}>
              {inputs_5.map((questao) => (
                <div
                  key={questao.type}
                  style={{ width: '100%' }}
                  className={
                    'form-group' +
                    (submitted && !questao.state ? ' has-error' : '')
                  }
                >
                  <TextField
                    label={questao.pergunta}
                    margin="normal"
                    name={questao.type}
                    value={questao.state}
                    className={classes.input}
                    placeholder={questao.pergunta}
                    onBlur={questao.blur}
                    type={questao.date}
                    onFocus={questao.focus}
                    onChange={this.handleChange}
                    startAdornment={
                      <InputAdornment position="start">
                        <img
                          className={
                            questao.imagem
                              ? classes.img_inputs
                              : classes.img_inputs_sem
                          }
                          src={questao.imagem ? questao.imagem : null}
                        />
                      </InputAdornment>
                    }
                  />
                </div>
              ))}
            </Grid>
          <Grid container justify={'center'} style={{ padding: '0 0' }}>
                {
                   user.signUpDocuments.map((doc)=>(
                      <div style={window.innerWidth > 900 ?{width:'90%',padding:'20px',cursor:'pointer',background:"#fff",borderStyle:'solid',borderColor:"#C8C8C8",borderWidth:"1px",borderRadius:'5px',marginTop:"5px"}:{width:'70%',padding:'20px',cursor:'pointer',background:"#fff",borderStyle:'solid',borderColor:"#C8C8C8",borderWidth:"1px",borderRadius:'5px',marginTop:"5px"}} 
                      onClick={async()=>{ await this.handleOpenRG(doc)}}
                      > 
                        <div style={{float:'left'}}>{doc.document == "PASSPORT" ? "PASSAPORTE" : doc.document}</div> 
                      </div>  
                   ))
                }
          </Grid>
          </Grid>
          
            <Grid container justify={'center'} style={{ padding: '20px 0',marginTop:'50px' }}>
              <Fab
                onClick={this.handleClear}
                variant="extended"
                aria-label="Delete"
                className={classes.fab}
                color="primary"
                style={{
                  fontSize: '10px',
                  width: '30%',
                  height: '36px',
                  background: 'white',
                  color: 'blue',
                  boxShadow: 'none',
                  border: '1px solid',
                  margin: '1%',
                }}
              >
                Limpar / Clean
              </Fab>

              <Fab
                variant="extended"
                aria-label="Delete"
                className={classes.fab}
                color="primary"
                style={{
                  fontSize: '10px',
                  width: '30%',
                  height: '36px',
                  background:
                    'linear-gradient(135deg, rgba(20,51,123,1) 0%, rgba(26,68,134,1) 22%, rgba(77,170,155,1) 73%, rgba(86,186,167,1) 100%)',
                  boxShadow: 'none',
                  border: '1px white solid',
                  marginRight: '5px',
                  margin: '1%',
                }}
                type={'submit'}
              >
                Cadastrar / Register
              </Fab>
            </Grid>
          
        </form>
        {registering && (
          <div className={classes.overlay}>
            <div className="loader">Loading...</div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { registering } = state.registration;
  const { editAcionamento } = state.editAcionamento;
  const { convenios } = state.convenios;
  const { message, type, open, loading } = state.alert;
  const { user } = state;
  return {
    registering,
    editAcionamento,
    message,
    type,
    open,
    loading,
    convenios,
    user,
  };
}

AcionamentoGru.propTypes = {
  classes: PropTypes.object.isRequired,
};

const connectedRegisterPage = connect(mapStateToProps)(AcionamentoGru);
export default withStyles(styles)(connectedRegisterPage);
