import { history } from '../helpers';

require('dotenv-flow').config();

const URL_CONECTA = process.env.REACT_APP_API_CONECTA;

export const userService = {
  register,
  RecoverPassword,
  handleResponse,
  fetchWithTimeout,
};

function login(username, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      username: username,
      password: password,
    }),
  };

  return fetchWithTimeout(
    `${URL_CONECTA}/servico/login/session`,
    requestOptions,
    10000
  )
    .then(handleResponse)
    .then((user) => {
      if (user.token) {
        localStorage.setItem('user', JSON.stringify(user));
      }
      return user;
    });
}

function RecoverPassword(username) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email: username,
    }),
  };
  return fetchWithTimeout(
    `${URL_CONECTA}/servico/login/forgot`,
    requestOptions,
    5000
  )
    .then(handleResponse)
    .then((message) => {
      return message;
    });
}



async function register(user) {
  var {
    email,
    name,
    lastName,
    cpf,
    passport,
    date,
    nacionalidade,
    sex,
    phone,
    phone2,
    phone3,
    rg,
    id_requisicao_parceiro,
    convenio,
    city,
    regiao,
    pais,
    codPais,
    numberCard,
    startTime,
    streetCep,
    city,
    regiao,
    pais
  } = user;
  
  let infoattendance = await login(process.env.REACT_APP_API_X ,process.env.REACT_APP_API_W)

  convenio = convenio.split('|');
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token':infoattendance.accessToken,
      _id: infoattendance.user._id
    },
    body: JSON.stringify({
      email: email,
      convenio: convenio[0],
      convenioDescricao: convenio[1],
      name: name,
      lastName: lastName,
      document: nacionalidade === 'B' ? cpf : passport,
      documentType: nacionalidade === 'B' ? 'CPF' : 'PASSAPORTE',
      rg: rg,
      birthDate: date,
      phone: phone,
      homePhone: phone2,
      phone2: phone3,
      gender: sex,
      partnerID: id_requisicao_parceiro,
      nationality: 'Brasileira',
      naturalness: null,
      city: city,
      state: regiao,
      country: pais,
      codPais: codPais,
      startTime: startTime,
      numberCard: numberCard,
      streetCep: streetCep,
      
    }),
  };
  return fetch(`${URL_CONECTA}/servico/adm/acionamentos/create`, requestOptions)
    .then(handleResponse)
    .then((body) => {
      if (body) {
        return body.url;
      } else {
        return false;
      }
    });
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(data);
    }
    return data;
  });
}

function fetchWithTimeout(url, requestOptions, timeout) {
  return new Promise((resolve, reject) => {
    let timer = setTimeout(
      () => reject(new Error('Request timed out')),
      timeout
    );
    fetch(url, requestOptions)
      .then(
        (response) => resolve(response),
        (err) => reject(err)
      )
      .catch((err) => {
        reject(err);
      });
  });
}
