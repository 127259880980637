import { alertConstants } from '../constants'

export function alert(state = {}, action) {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        type: 'success',
        message: action.message,
        open: true,
        loading: true,
      }
    case alertConstants.ERROR:
      return Object.assign({}, state, {
        type: 'error',
        message: action.message,
        open: true,
        loading: true,
      })
    case alertConstants.CLEAR:
      return Object.assign({}, state, {
        type: action.varient ? action.varient : 'error',
        message: action.message,
        open: false,
        loading: null,
      })
    default:
      console.debug('notification reducer :: hit default', action.type)
      return state
  }
}
