import React from 'react';
import MenuPainel from '../components/MenuPainel'
import CssBaseline from '@material-ui/core/CssBaseline';
import AdmAcionamento   from '../components/Acionamento'
import PaperForm from "../components/PaperForm"

class Acionamento extends React.Component {
    render() {
        return (
            <React.Fragment>
                <CssBaseline/>
                <MenuPainel>
                    <PaperForm
                        title={''}
                        margin={'15px 0'}
                        elevation={24}
                        componente={<AdmAcionamento />}>
                    </PaperForm>
                </MenuPainel>
            </React.Fragment>
        );
    }
}

const connectedAssinar = (Acionamento);
export { connectedAssinar as Acionamento};
