import {
  acionamentoConstants,
  editAcionamentoConstants,
  userConstants,
  cepConstants,
} from '../constants';
import { conveniosConstants } from '../constants';
import { acionamentoService } from '../services';
import { conveniosService } from '../services';
import { alertActions } from './';
import { history } from '../helpers';
import { resolveConfig } from 'prettier';

export const acionamentoActions = {
  getAllAcionamentos,
  deleteAcionamentos,
  editAcionamentos,
  editAcionamentosWithoutRedirect,
  getAllConvenios,
  confirmedAcionamentos,
  getAddressFromCep,
  paginateAcionamentos,
  generateImageUrl
};

function generateImageUrl(bucketObject={}){
  return new Promise((resolve,reject)=>{
    acionamentoService.getUrlImages(bucketObject).then((res)=>{
      resolve(res);
    }).catch((err)=>{
      resolve({signedURL:""});
    })
  })
}


function getAddressFromCep(cep) {
  return (dispatch) => {
    dispatch(request());
    acionamentoService
      .GetAddressFromCep(cep)
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: cepConstants.CEP_REQUEST };
  }
  function success(result) {
    return { type: cepConstants.CEP_SUCCESS, result };
  }
  function failure(error) {
    return { type: cepConstants.CEP_FAILURE, error };
  }
}


function editAcionamentosWithoutRedirect(acionamento) {
  return (dispatch) => {
    dispatch(request(acionamento));
    if (acionamento) {
      dispatch(success(acionamento));
    } else {
      dispatch(failure());
    }
  };
  function request() {
    return { type: editAcionamentoConstants.ACIONAMENTO_EDIT_REQUEST };
  }
  function success(acionamento) {
    return {
      type: editAcionamentoConstants.ACIONAMENTO_EDIT_SUCCESS,
      acionamento,
    };
  }
  function failure() {
    return { type: editAcionamentoConstants.ACIONAMENTO_EDIT_FAILURE };
  }
}

function paginateAcionamentos(pageSize=5,page=1,queryString=""){
  return new Promise((resolve)=>{
      acionamentoService.paginateAllAcionamentos(pageSize,page,queryString).then((result)=>{
        resolve(result)
      }).catch((err)=>{
        return resolve({
            docs:[],
            limit:5,
            page:1,
            pages:1,
            total:0
        })
    })
    })
}

function getAllAcionamentos() {
  return (dispatch) => {
    dispatch(request());

    acionamentoService
      .GetAllAcionamentos()
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: acionamentoConstants.ACIONAMENTO_REQUEST };
  }
  function success(acionamentos) {
    return { type: acionamentoConstants.ACIONAMENTO_SUCCESS, acionamentos };
  }
  function failure(error) {
    return { type: acionamentoConstants.ACIONAMENTO_FAILURE, error };
  }
}

function confirmedAcionamentos(acionamento) {
  var tempData = acionamento.birthDate.split('/');
  acionamento.date = `${tempData[2]}-${tempData[1]}-${tempData[0]}`;
  return (dispatch) => {
    dispatch(request());

    acionamentoService
      .confirmedAcionamentos(acionamento)
      .then(
        (body) => {
          history.push('/adm');
          dispatch(success(body));
          dispatch(alertActions.success(body.message));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: userConstants.REGISTER_REQUEST };
  }
  function success() {
    return { type: userConstants.REGISTER_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function deleteAcionamentos(id, reason) {
  return (dispatch) => {
    dispatch(request());

    acionamentoService
      .deleteAcionamentos(id, reason)
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: acionamentoConstants.ACIONAMENTO_REQUEST };
  }
  function success() {
    return { type: acionamentoConstants.ACIONAMENTO_SUCCESS };
  }
  function failure(error) {
    return { type: acionamentoConstants.ACIONAMENTO_FAILURE, error };
  }
}

function editAcionamentos(acionamento) {
  return (dispatch) => {
    dispatch(request(acionamento));
    if (acionamento) {
      dispatch(success(acionamento));
    } else {
      dispatch(failure());
    }
    history.push('/adm/acionamento');
  };
  function request() {
    return { type: editAcionamentoConstants.ACIONAMENTO_EDIT_REQUEST };
  }
  function success(acionamento) {
    return {
      type: editAcionamentoConstants.ACIONAMENTO_EDIT_SUCCESS,
      acionamento,
    };
  }
  function failure() {
    return { type: editAcionamentoConstants.ACIONAMENTO_EDIT_FAILURE };
  }
}

function getAllConvenios() {
  return (dispatch) => {
    dispatch(request());

    conveniosService
      .GetAllConvenios()
      .then(
        (body) => {
          dispatch(success(body));
        },
        (error) => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.error_description));
        }
      )
      .catch((error) => {
        dispatch(failure(error.toString()));
      });
  };

  function request() {
    return { type: conveniosConstants.CONVENIOS_REQUEST };
  }
  function success(convenios) {
    return { type: conveniosConstants.CONVENIOS_SUCCESS, convenios };
  }
  function failure(error) {
    return { type: conveniosConstants.CONVENIOS_FAILURE, error };
  }
}
