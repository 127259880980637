import { combineReducers } from 'redux';
import { registration } from './registration.reducer';
import { acionamento } from './acionamento.reducer';
import { cancelamento } from './cancelamento.reducer';
import { atividades } from './atividades.reducer';
import { editAcionamento } from './editAcionamento.reducer';
import { historico } from './historico.reducer';
import { convenios } from './convenios.reducer';
import { alert } from './alert.reducer';
import { alertContingencia } from './alertContingencia.reducer';
import { editHistoricos } from './editHistorico.reducer';
import { country } from './country.reducer';
import { region } from './region.reducer';
import { citie } from './citie.reducer';
import { acionamentospassagem } from './actionpassage.reducer';
import { paacionamentolist } from './paacionamentolist.reducer';
import { paacionamentocreate } from './paacionamentocreate.reducer';
import { editPAcionamento } from './editPAcionamento.reducer';
import { cockpit } from './cockpit.reducer';
import { cFinalizados } from './cFinalizados.reducer';
import { cancelamentoUser } from './cancelamentoUser.reducer';
import { cepToAddress } from './getAddresFromCep.reducer';
import { cancelamentoList } from './cancelamentoList.reducer';

const rootReducer = combineReducers({
  alertContingencia,
  alert,
  convenios,
  registration,
  acionamento,
  editAcionamento
});

export default rootReducer;
